<template>
    <ValidationObserver ref="observe">
        <b-form>
            <ek-dialog
                ref="addDialog"
                title="إضافة إشعار"
                size="md"
                placeholder="ابحث عن اشعار محدد"
                btnText="اضافة إشعار"
                @ok="submit()"
                @close="reset"
                @search="setSearch"
            >
                <template #body>
                    <b-form-group label="إرسال إلى" label-for="sendTo">
                        <div
                            id="notifiSwitch"
                            class="d-flex justify-content-center align-items-baseline"
                        >
                            <label class="mx-1">تطبيق الطالب</label>
                            <b-form-checkbox
                                id="sendTo"
                                class="custom-control-warning"
                                v-model="ToPos"
                                switch
                            >تطبيق نقاط البيع</b-form-checkbox>
                        </div>
                    </b-form-group>

                    <ek-input-text
                        :rules="[
                            { type: 'required', message: 'العنوان مطلوب' },
                        ]"
                        name="title"
                        placeholder="ادخل العنوان الرئيسي "
                        label=" العنوان الرئيسي"
                        v-model="notificationDto.title"
                    ></ek-input-text>

                    <ek-input-textarea
                        :rules="[
                            { type: 'required', message: 'النص  مطلوب' },
                        ]"
                        name="phoneNumber"
                        placeholder="أدخل النص"
                        label="النص "
                        v-model="notificationDto.body"
                    ></ek-input-textarea>
                </template>
            </ek-dialog>
        </b-form>
    </ValidationObserver>
</template>
<script>

import { ValidationObserver } from "vee-validate";
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";

export default {
    components: {
        ValidationObserver
    },
    computed: {
        ...mapState({
            notificationDto: state => state.notifications.notificationDto
        }),
        ...mapGetters(['notificationsList'])
    },
    data() {
        return {
            ToPos: false,
        };
    },
    methods: {
        ...mapActions(["addNotifications"]),
        ...mapMutations(['Reset_Notifications_Dto']),
        submit() {
            this.$refs.observe.validate().then(success => {
                if (success) {
                    this.addNotifications({
                        title: this.notificationDto.title,
                        body: this.notificationDto.body,
                        sendTo: this.notificationDto.sendTo = this.ToPos ? 2 : 1
                        
                    });
                    this.$refs.addDialog.close();
                    this.$refs.observe.reset();
                }
            });
        },
        setSearch(query) {
            this.$store.commit("Set_Search_Dto", {
                keys: ["title", "body", "sendTo", "date"],
                query,
            });
        },
        reset() {
            this.$refs["observe"].reset();
            Object.assign(this.notificationDto, {
                title: "",
                body: "",
                sendTo: 1,
            });
        },
    }
};
</script>
